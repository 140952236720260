<template>
    <Menu actual="/material-didactico" class="mt-6" />

	<div class="bg-white overflow-hidden">
		<div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
			<div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
			<div class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
				<div>
					<h2 class="text-base text-morado font-semibold tracking-wide uppercase">6 a 8 años</h2>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-cyan sm:text-4xl">¿Cómo soy?</h3>
				</div>
			</div>
			<div class="mt-6 lg:grid lg:grid-cols-2 lg:gap-8">
				<div class="relative lg:row-start-1 lg:col-start-2">
					<svg class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
						<defs>
							<pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
								<rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
							</pattern>
						</defs>
						<rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
					</svg>
					<div class="relative text-base mx-auto max-w-prose lg:max-w-none">
						<figure>
							<div>
								<img class="rounded-lg shadow-lg max-h-96 md:max-h-screen mx-auto" :src="require('@/assets/material-didactico/portada-2.jpg')" alt="¿Cómo soy?" />
							</div>
						</figure>
					</div>

					<div class="mt-12 hidden md:flex justify-around">
						<router-link to="/material-didactico/yo-me-quiero-yo-me-cuido">
							<img class="rounded-lg shadow-lg h-56 md:h-64 mx-auto" :src="require('@/assets/material-didactico/portada-1.jpg')" alt="Yo me quiero yo me cuido" />
						</router-link>
						<router-link to="/material-didactico/una-nueva-vida">
							<img class="rounded-lg shadow-lg h-56 md:h-64 mx-auto" :src="require('@/assets/material-didactico/portada-3.jpg')" alt="Una nueva vida" />
						</router-link>
					</div>
				</div>
				<div class="mt-8 lg:mt-0">
					<div class="flex space-x-2 items-center">
						<a href="https://apps.apple.com/us/app/c%C3%B3mo-soy/id1608936371" target="_blank" class="block"><img class="h-12" :src="require('@/assets/material-didactico/appstore.png')" alt="App Store" /></a>
						<a href="https://play.google.com/store/apps/details?id=mx.cuentamecomo.comosoy" target="_blank" class="block"><img class="h-12" :src="require('@/assets/material-didactico/playstore.png')" alt="Play Store" /></a>
					</div>
					<div class="mt-4 text-base max-w-prose mx-auto">
						<p class="text-xl font-light text-gray-500">App con 20 juegos interactivos y un libro descargable en PDF.</p>
					</div>

					<div class="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
						<div class="text-azul-light font-bold text-xl mt-12">¿Qué aprenden?</div>
						
						<p>Es natural que niñas y niños desde muy pequeños se pregunten  sobre  el cuerpo y sus funciones; diferencias anatómicas entre ambos sexos, y  aspectos generales del embarazo.</p>

						<p>Todas estas curiosidades  son reflejo de la etapa que están viviendo y de la necesidad de conocerse a sí mismos y el mundo que los rodea.</p>

						<p>El material <span class="font-semibold">¿Cómo soy?</span> te ayudará a  resolver estas inquietudes de forma positiva y natural.</p>

						<ul>
							<li>Es una guía para saber qué, cuánto y cómo decirlo.</li>
							<li>Para dar respuestas francas, con los términos adecuados para inculcar una relación positiva con su cuerpo, para que lo quieran, lo cuiden y aprecien cada una de sus partes y capacidades</li>
							<li>Te apoya en estas primeras conversaciones para estrechar lazos con ellas y ellos, que se sientan en confianza para hacer más preguntas en el futuro con la seguridad  que obtendrán información verdadera y que no tiene nada de malo hablar de este tema.</li>
							<li>Además del aspecto informativo aborda temas como la autoestima, valores, equidad, etc. factores que son muy importantes en la construcción de la personalidad y determinantes en el comportamiento futuro.</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="mt-12 md:hidden flex justify-around">
				<router-link to="/material-didactico/yo-me-quiero-yo-me-cuido">
					<img class="rounded-lg shadow-lg h-56 md:h-72 mx-auto" :src="require('@/assets/material-didactico/portada-1.jpg')" alt="Yo me quiero yo me cuido" />
				</router-link>
				<router-link to="/material-didactico/una-nueva-vida">
					<img class="rounded-lg shadow-lg h-56 md:h-72 mx-auto" :src="require('@/assets/material-didactico/portada-3.jpg')" alt="Una nueva vida" />
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
// import { AcademicCapIcon } from '@heroicons/vue/outline'
import Menu from '@/components/core/Menu'

export default {
	components: {
		Menu
	}
}
</script>